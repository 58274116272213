<template lang="pug">
.accordion
  slot
</template>

<script>
import { ref, provide } from 'vue';

export default {
  name: 'Accordion',
  setup(props) {
    const activePanelKey = ref(null);

    provide('activePanelKey', activePanelKey);

    return {
      activePanelKey,
    };
  },
}
</script>
