<template lang="pug">
.accordion-panel(@click="togglePanel" :class="{ 'is-opened': isActive }")
  .header
    slot(v-if="$slots.title" name="title" :opened="isActive")
    .title(v-else) {{ title }}
    .arrow-wrap(:class="{ 'is-rotate': isActive }")
      Arrow.arrow

  Collapse.content(:when="isActive")
    slot(:opened="isActive")
</template>

<script>
import { ref, inject, watch } from 'vue';
import { Collapse } from 'vue-collapsed'
import Arrow from '@/assets/svg/arrow-down.svg'

export default {
  name: 'AccordionPanel',
  components: {
    Collapse,
    Arrow
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: true,
    }
  },
  setup(props, { slots }) {
    const activePanel = inject('activePanelKey');

    const isActive = ref(false);

    const togglePanel = () => {
      activePanel.value = isActive.value ? null : props.id;
    };

    watch(
      () => activePanel.value,
      (newActivePanel) => {
        isActive.value = newActivePanel === props.id;
      }
    );

    return {
      isActive,
      togglePanel
    };
  },
};
</script>

<style lang="scss" scoped>
.accordion-panel {
  border: none;
  border-bottom: get-vw(1) solid $button-xs-text;
  padding-bottom: get-vw(72);
  cursor: pointer;

  & + & {
    margin-top: get-vw(72);
  }

  &.is-opened {
    padding-bottom: 0;
  }

  &:last-of-type {
    border: none;
  }
}
.header {
  align-items: center;
  display: flex;
}

.arrow-wrap {
  display: flex;
  margin-left: get-vw(24);
  transition: transform 0.1s ease-in-out;

  &.is-rotate {
    transform: rotate(-180deg);
  }
}

.arrow {
  height: get-vw(12);
}

.title {
  @include headline-h2-h2;
}

.content {
  margin-top: get-vw(72);
}

@include tablet {
  .title {
    @include text-t10("md");
  }
  .accordion-panel {
  padding-bottom: get-vw(22, "md");
  }
  .content {
  margin-top: get-vw(32, "md");
  }
}

@include mobile {
  .title {
    @include text-t4-medium-m("sm");
  }
  .accordion-panel {
    padding-bottom: get-vw(22, "sm");
    border-bottom: get-vw(1, "sm") solid $button-xs-text;
  }
  .content {
    margin-top: get-vw(22, "sm");
  }

  .arrow {
    height: get-vw(6, "sm");
  }

  .header {
    align-items: baseline;
    justify-content: space-between;
  }
}
</style>
